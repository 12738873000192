// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()

import "../stylesheets/application.scss"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
Vue.use(TurbolinksAdapter)

import VueSplide from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
Vue.use(VueSplide)

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

document.addEventListener('turbolinks:load', () => {
  new Vue({
    el: '#application',
    data() {
      return {
        current: 'plg',
        diploma: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.scrolled)
      this.scrolled()
    },
    methods: {
      scrolled() {
        if (this.$refs.mobileHeader) {
          if (window.scrollY === 0) {
            this.$refs.mobileHeader.classList.remove('shadow-lg')
          } else {
            this.$refs.mobileHeader.classList.add('shadow-lg')
          }
        }
      }
    }
  })
})
